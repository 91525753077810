<template lang="pug">
.contact-us-dropdown.is-flex(@click="onDropdownClick")
  span {{$t('layout.header.firstLine.rightBlock.contact')}}
  i.fa.fa-angle-down(:class="{open: showContacts.state}")
</template>

<script>
export default {
  name: 'ContactUsDropdownComponent',

  inject: ['showContacts'],

  // data: () => ({
  //   open: false
  // }),

  methods: {
    onDropdownClick() {
      // this.$emit('change')
      this.$root.$emit('toggle-contacts')
      // this.open = !this.open
    }
  }
}
</script>

<style lang="sass" scoped>
.contact-us-dropdown
  cursor: pointer
  align-items: center
  border: 1px solid #ffffff
  padding: 0 12px
  i
    margin-left: 10px
    color: #ffffff
    @include transition(all, .3s)
    &.open
      transform: rotate(-180deg)
  & span
    font-family: $lato
    font-size: 12px
    line-height: 24px
    text-transform: uppercase
    color: #ffffff
</style>
